export const contractsDetailsStyles = {
  items: {
    borderBottom: 'solid 1px rgba(151, 151, 151, 0.6)',
    borderRight: 'solid 1px rgba(151, 151, 151, 0.6)',
    padding: '40px 40px',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '2em',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: '0.3em',
  },
  sectionTitle: {
    color: '#004165',
    marginBottom: '0.5em',
  },
  sectionTitleAlt: {
    color: '#004165',
    marginTop: '0.5em',
  },
  item: {
    display: 'flex',
    gap: '1em',
    alignItems: 'center',
  },
  itemLabel: {
    color: '#333333',
  },
  itemValue: {
    fontWeight: 'bold',
    color: '#333333',
  },
}
