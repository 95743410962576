import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Grid, ListItemText } from '@material-ui/core'

import { gridRowStyles } from './grid-row.styles'
import { format } from 'date-fns'

export const GridRowComponent = withStyles(gridRowStyles)(
  ({ classes, items, data, spacing = 4, justify = 'flex-start' }) => {
    const intl = useIntl()

    return (
      <Grid container spacing={spacing} justify={justify}>
        {items.map((item, i) => {
          let multiline = item.multiline ? { whiteSpace: 'pre-line' } : {}
          let text = item.valueParsed ? item.valueParsed(data, intl) : data[item.value]
          if (item.isDate) {
            const date = new Date(data[item.value])
            text = format(date, 'dd/MM/yyyy')
          }
          return (
            <Grid item key={i}>
              <ListItemText
                primary={intl.formatMessage({ id: item.label })}
                secondary={text}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'body2' }}
                style={multiline}
              ></ListItemText>
            </Grid>
          )
        })}
      </Grid>

      //   <div className={classes.container}>
      //     {items.map((item, i) => (
      //       <div key={i} name={i} className={classes.item}>
      //         <Typography variant="subtitle2">{item.label}</Typography>
      //         <Typography variant="body2">
      //           {item.valueParsed ? item.valueParsed(data) : data[item.value]}
      //         </Typography>
      //       </div>
      //     ))}
      //   </div>
    )
  }
)
