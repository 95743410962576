import React, { useEffect, useRef } from 'react'
import { Box, OutlinedInput, Typography } from '@material-ui/core'

export const CodeInput = (props) => {
  const inputsRef = useRef([])
  const { name, onChange, label, ...otherProps } = props

  useEffect(() => {
    inputsRef.current[0].focus()
  }, [])

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join('')
    const event = { target: { name: name, value: res } }
    onChange && onChange(event)
  }

  const handleOnChange = (e) => {
    const {
      target: { value },
    } = e

    const nextElementSibling = e.target.parentElement?.nextElementSibling?.firstChild

    if (value.length > 1) {
      e.target.value = value.charAt(0)
      nextElementSibling && nextElementSibling.focus()
    } else {
      if (value.match('[0-9]{1}')) {
        nextElementSibling && nextElementSibling.focus()
      } else {
        e.target.value = ''
      }
    }
    sendResult()
  }

  const handleOnKeyDown = (e) => {
    const { key } = e
    const target = e.target
    const previousElementSibling = target.parentElement?.previousElementSibling?.firstChild
    if (key === 'Backspace') {
      if (target.value === '' && previousElementSibling) {
        previousElementSibling.focus()
        previousElementSibling.value = ''
        e.preventDefault()
      } else {
        target.value = ''
      }
      sendResult()
    }
  }

  const handleOnFocus = (e) => {
    e.target.select()
  }

  const handleOnPaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text')

    let currentInput = 0

    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i)
      const currentInputElement = inputsRef.current[currentInput]
      const currentValue = currentInputElement.value
      if (pastedCharacter.match('[0-9]{1}')) {
        if (!currentValue) {
          currentInputElement.value = pastedCharacter
          const nextElementSibling =
            currentInputElement.parentElement?.nextElementSibling?.firstChild

          if (nextElementSibling) {
            nextElementSibling.focus()
            currentInput++
          }
        }
      }
    }
    sendResult()

    e.preventDefault()
  }

  return (
    <Box display={'flex'} flexDirection={'column'} style={{ gap: 4 }}>
      {label && (
        <Typography variant={'body1'}>
          {label}
        </Typography>
      )}
      <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        {Array.from(Array(6).keys()).map((i) => (
          <OutlinedInput
            key={i}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleOnFocus}
            onPaste={handleOnPaste}
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
            }}
            type="tel"
            inputRef={(el) => (inputsRef.current[i] = el)}
            autoComplete={'off'}
            style={{
              height: 40,
              width: 40,
              mr: 0.5,
              ml: 0.5,
            }}
            {...otherProps}
          />
        ))}
      </Box>
    </Box>
  )
}
