export const loginFormStyles = (theme) => ({
  inputIcon: {
    color: theme.palette.primary.main,
  },
  link: {
    margin: '0 0.1em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
})
