import React, { useContext } from 'react'
import { Grid, Hidden, Typography, Icon, Box } from '@material-ui/core'
import { contractsDetailsStyles } from './contracts-details.styles'
import { ReactComponent as ContractHolder } from '../../../../resources/images/contract-holder.svg'
import { ReactComponent as ContractContact } from '../../../../resources/images/contract-contact.svg'
import { ReactComponent as ContractPayment } from '../../../../resources/images/contract-payment.svg'
import { ReactComponent as ContractCorresp } from '../../../../resources/images/contract-corresp.svg'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectClient } from '../../../searchUser/slices/searchUser.slice'
import { UnblurInformationDialog } from '../../../two-factor/components/two-factor-dialog.component'
import { ModalContext } from '../../../../ui/modal/contexts'
import { selectUser } from '../../../profile/slices'

export function ContractDetailsComponent({ data }) {
  const { formatMessage } = useIntl()
  const clientInfo = useSelector(selectClient)
  const user = useSelector(selectUser)
  const {
    actions: { open },
  } = useContext(ModalContext)
  const isTwoFactorAuthenticated = useSelector(
    (state) => state.global.authData.isTwoFactorAuthenticated
  ) // Estado de 2FA desde Redux

  const handleOpenUnblurDialog = () =>
    open({
      Component: UnblurInformationDialog,
      props: {
        close: () => {}, // Se maneja el cierre desde el diálogo directamente
      },
      type: 'responsive',
    })

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6} style={contractsDetailsStyles.items}>
        <div style={contractsDetailsStyles.sectionContainer}>
          <Hidden mdDown>
            <ContractHolder />
          </Hidden>
          <div style={contractsDetailsStyles.itemContainer}>
            <Typography variant="h6" style={contractsDetailsStyles.sectionTitle}>
              {formatMessage({ id: 'pages.contracts.details.panel1.title' })}
            </Typography>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel1.title' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>{data?.pagador}</span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel1.nombre' })}
              </span>
              {isTwoFactorAuthenticated ? (
                data?.contrato
              ) : (
                <Box
                  style={{ cursor: 'pointer' }}
                  display="flex"
                  alignItems="center"
                  flexWrap="nowrap"
                  onClick={handleOpenUnblurDialog}
                >
                  <Typography variant={'body1'} style={{ fontWeight: 'bold', marginRight: '8px' }}>
                    {data?.contrato}
                  </Typography>
                  <Icon fontSize="inherit">visibility_off</Icon>
                </Box>
              )}
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel1.numero_abonado' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>Es usted el titular del contrato</span>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} style={contractsDetailsStyles.items}>
        <div style={contractsDetailsStyles.sectionContainer}>
          <Hidden mdDown>
            <ContractContact />
          </Hidden>
          <div style={contractsDetailsStyles.itemContainer}>
            <Typography variant="h6" style={contractsDetailsStyles.sectionTitle}>
              {formatMessage({ id: 'pages.contracts.details.panel2.title' })}
            </Typography>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel2.alias' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>
                {clientInfo?.nombre || user.nickname}
              </span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel2.nombre_apellidos' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>
                {clientInfo?.nombre ? clientInfo?.nombre + ' ' + clientInfo.apellidos : user.name}
              </span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel2.telefono' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>
                {clientInfo?.telefono1 || user.phone}
              </span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel2.fax' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>{clientInfo?.fax}</span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel2.email' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>
                {clientInfo?.email || user.email}
              </span>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} style={contractsDetailsStyles.items}>
        <div style={contractsDetailsStyles.sectionContainer}>
          <Hidden mdDown>
            <ContractPayment />
          </Hidden>
          <div style={contractsDetailsStyles.itemContainer}>
            <Typography variant="h6" style={contractsDetailsStyles.sectionTitle}>
              {formatMessage({ id: 'pages.contracts.details.panel3.title' })}
            </Typography>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel3.forma_pago' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>{data?.forma_pago}</span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel3.iban' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>
                {isTwoFactorAuthenticated ? (
                  data?.iban
                ) : (
                  <Box
                    style={{ cursor: 'pointer' }}
                    display="flex"
                    alignItems="center"
                    flexWrap="nowrap"
                    onClick={handleOpenUnblurDialog}
                  >
                    <Typography
                      variant={'body1'}
                      style={{ fontWeight: 'bold', marginRight: '8px' }}
                    >
                      {data?.iban}
                    </Typography>
                    <Icon fontSize="inherit">visibility_off</Icon>
                  </Box>
                )}
              </span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemValue}>{data?.pagador}</span>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} style={contractsDetailsStyles.items}>
        <div style={contractsDetailsStyles.sectionContainer}>
          <Hidden mdDown>
            <ContractCorresp />
          </Hidden>
          <div style={contractsDetailsStyles.itemContainer}>
            <Typography variant="h6" style={contractsDetailsStyles.sectionTitleAlt}>
              {formatMessage({ id: 'pages.contracts.details.panel4.title1' })}
            </Typography>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemValue}>
                {data?.direccion_correspondencia}
              </span>
            </div>
            <Typography variant="h6" style={contractsDetailsStyles.sectionTitleAlt}>
              {formatMessage({ id: 'pages.contracts.details.panel4.title2' })}
            </Typography>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemValue}>{data?.direccion_suministro}</span>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={contractsDetailsStyles.items}>
        <div style={contractsDetailsStyles.sectionContainer}>
          <div style={contractsDetailsStyles.itemContainer}>
            <Typography variant="h6" style={contractsDetailsStyles.sectionTitle}>
              {formatMessage({ id: 'pages.contracts.details.panel5.title' })}
            </Typography>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel5.ultima_inspeccion' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>{data?.ultima_inspeccion}</span>
            </div>
            <div style={contractsDetailsStyles.item}>
              <span style={contractsDetailsStyles.itemLabel}>
                {formatMessage({ id: 'pages.contracts.details.panel5.proxima_inspeccion' })}
              </span>
              <span style={contractsDetailsStyles.itemValue}>{data?.proxima_inspeccion}</span>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
