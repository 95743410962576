import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { useWidth } from '../../../../core/utils'
import LogoGNRedesGLPFull from '../../../../resources/images/logo_GNRedesGLP_full.svg'
import { MenuComponent as Menu } from '../../../menu'
import { DEFAULT_ROUTE, MENU_ITEMS, PROFILE_ROUTE } from '../constants'
import { privateLayoutStyles } from './private-layout.styles'
import { APP_VERSION_MSSG, FOOTER_LINKS } from '../../public-layout/constants'
import { useIntl } from 'react-intl'
import { LanguagePickerComponent } from '../../../language-picker'
import LogoGNRedesGLP from '../../../../resources/images/logo_GNRedesGLP.svg'

export const PrivateLayoutComponent = withStyles(privateLayoutStyles)(({ children, classes }) => {
  let screenWidth = useWidth()
  let { formatMessage } = useIntl()

  const isMobileWidth = screenWidth === 'sm' || screenWidth === 'xs'

  const mobileView = MENU_ITEMS.length > 5
  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className={classes.headerLeftContent} style={mobileView ? { order: '2' } : {}}>
          <div className={classes.logo}>
            <Tooltip title={APP_VERSION_MSSG}>
              <Link to={DEFAULT_ROUTE}>
                {screenWidth === 'xs' ? (
                  <img src={LogoGNRedesGLP} alt={'nedgia logo'} style={{ width: 'auto', height: '60px' }} />
                ) : (
                  <img
                    src={LogoGNRedesGLPFull}
                    alt="Nedgia_Logo"
                    style={{ width: 'auto', height: '60px' }}
                  />
                )}
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className={classes.menu} style={mobileView ? { order: '1' } : {}}>
          <Menu items={MENU_ITEMS} isMobileWidth={isMobileWidth} mobileView={mobileView} />
        </div>
        <div className={classes.headerRightContent} style={mobileView ? { order: '3' } : {}}>
          {/* <Link to={SETTINGS_ROUTE}>
            <IconButton className={classes.iconButton}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Link>
           */}
          <LanguagePickerComponent />

          <Link to={PROFILE_ROUTE}>
            <IconButton className={classes.iconButton}>
              <AccountCircleOutlinedIcon />
            </IconButton>
          </Link>
        </div>
      </div>
      <div className={classes.mainArea}>
        <div className={classes.bodyWrapper}>{children}</div>
        <div className={classes.footerWrapper}>
          <div className={classes.footerContent}>
            <Typography variant="body1" paragraph>
              {FOOTER_LINKS.map((item, i) => (
                <span key={i}>
                <span className={classes.footerLink} onClick={() => window.open(item.link)}>
                  {formatMessage({ id: item.id })}
                </span>
                  {i !== FOOTER_LINKS.length - 1 && '|'}
              </span>
              ))}
            </Typography>
            <Typography variant="body1">
              © {new Date().getFullYear()} Gas Natural - Redes GLP
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
})
