export const CONTRACTS_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SET_LOADING',
}

export const CONTRACTS_INITIAL_STATE = {
  data: [],
  originalData: [],
  search: {},
  pagination: { total_reports: 0 },
  loading: false,
  msLoaded: false,
  error: false,
}