import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'


import { invoicesStyles } from './invoices.styles'
import { InvoicesHeader, InvoicesSearch, InvoicesTable } from '../../../features/invoices'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../features/profile/slices'
import { SearchInfoComponent } from '../../../features/searchUser/components/search-info'
import { InvoicesContext } from '../../../features/invoices/contexts'
import { selectClient } from '../../../features/searchUser/slices/searchUser.slice'

export const InvoicesPage = withStyles(invoicesStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const role = useSelector(selectRole)
  const clientInfo = useSelector(selectClient)

  return (
    <InvoicesContext>
    <Box className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        {role === 'ATR' && (<Box mb={2}>
          <InvoicesSearch />
          <SearchInfoComponent />
        </Box>)}

        <>
        <Box mb={2}>
          <Typography variant="h4" color="primary" paragraph>
            {formatMessage({ id: 'pages.invoices.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <InvoicesHeader />
        </Box>
        <Box mb={2}>
          <InvoicesTable />
        </Box>
          </>
      </Container>
    </Box>
    </InvoicesContext>
  )
})
