import React, { createContext, useContext } from 'react'
import { useInvoicesReducer } from '../hooks/invoices-reducer.hook'


const InvoicesContext = createContext()

export const InvoicesContextProvider = ({ children }) => {
  const reducer = useInvoicesReducer()
  return <InvoicesContext.Provider value={reducer}>{children}</InvoicesContext.Provider>
}

export const useInvoicesContext = () => useContext(InvoicesContext)