import { configureStore } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { profileReducer } from '../../features/profile/slices'
import { globalReducer } from '../../features/global'
import { searchUserReducer } from '../../features/searchUser/slices/searchUser.slice'
import { twoFactorReducer } from '../../features/two-factor/slices/two-factor.slice'

export default configureStore({
  reducer: {
    global: globalReducer,
    router: connectRouter(createBrowserHistory()),
    profile: profileReducer,
    searchUser: searchUserReducer,
    twoFactor: twoFactorReducer,
  },
})
