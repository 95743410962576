export const passwordInputStyles = {
  root: {
    width: '100%',
  },
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    // fontSize: 16,
  },
}
