import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { PublicLayoutComponent } from '../../ui'
import { Login, Signup, SignupConfirmation } from '../../pages'

export const PublicRouter = () => (
  <PublicLayoutComponent>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup/confirmation/:signupToken" component={SignupConfirmation} />
      <Route path="/signup" component={Signup} />
      <Redirect from="*" to="/login" />
    </Switch>
  </PublicLayoutComponent>
)
