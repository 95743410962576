import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

import { MATERIAL_COMPONENTS_OVERRIDES } from './overrides'
import { APP_THEME_COLORS } from './colors'

// Default theme: https://material-ui.com/customization/default-theme/
export let theme = responsiveFontSizes(
  createMuiTheme({
    overrides: MATERIAL_COMPONENTS_OVERRIDES,
    palette: {
      primary: {
        main: APP_THEME_COLORS[30],
        contrastText: APP_THEME_COLORS[10],
      },
      secondary: {
        main: APP_THEME_COLORS[40],
        contrastText: APP_THEME_COLORS[20],
      },
    },
    typography: {
      fontFamily: 'FSEmeric-Regular',
      h4: {
        fontFamily: 'FSEmeric-Medium',
      },
      h5: {
        fontFamily: 'FSEmeric-Medium',
      },
      h6: {
        fontFamily: 'FSEmeric-Medium',
        lineHeight: 1,
      },
      subtitle1: {
        fontFamily: 'FSEmeric-Medium',
        lineHeight: 1.25,
      },
      body1: {
        lineHeight: 1.25,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  })
)
