import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InvoicesTableConstants } from './invoices-table.constants'
import MaterialTable from 'material-table'
import { invoicesTableStyles } from './invoices-table.styles'
import { useIntl } from 'react-intl'
import { invoiceDataPreparation } from '../../logics'
import { InvoicesDetailComponent } from '../detail'
import { parse } from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import { useInvoicesContext } from '../../contexts'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../profile/slices'
import { selectClient } from '../../../searchUser/slices/searchUser.slice'

const localization = (formatMessage) => ({
  pagination: {
    labelDisplayedRows: formatMessage({ id: 'pagination.labelDisplayedRows' }),
    labelRowsSelect: formatMessage({ id: 'pagination.labelRowsSelect' }),
    firstAriaLabel: formatMessage({ id: 'pagination.firstAriaLabel' }),
    firstTooltip: formatMessage({ id: 'pagination.firstTooltip' }),
    previousAriaLabel: formatMessage({ id: 'pagination.previousAriaLabel' }),
    previousTooltip: formatMessage({ id: 'pagination.previousTooltip' }),
    nextAriaLabel: formatMessage({ id: 'pagination.nextAriaLabel' }),
    nextTooltip: formatMessage({ id: 'pagination.nextTooltip' }),
    lastAriaLabel: formatMessage({ id: 'pagination.lastAriaLabel' }),
    lastTooltip: formatMessage({ id: 'pagination.lastTooltip' }),
  },
  body: {
    emptyDataSourceMessage: formatMessage({ id: 'table.body.emptyDataSourceMessage' }),
  },
})

export const InvoicesTableComponent = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const [rowSelected, setRowSelected] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const role = useSelector(selectRole)
  const clientInfo = useSelector(selectClient)

  const language = useSelector((state) => state.global.locale)

  const { data, loading, getInvoicesTable } = useInvoicesContext()

  useEffect(() => {
    const searchString = location.search.split('?')[1]

    const urlSearchParams = parse(searchString)
    const searchParams = {
      ...urlSearchParams,
    }
    if (role === 'ATR') {
      if (clientInfo['nombre']) {
        searchParams['client'] = clientInfo['dni']
        getInvoicesTable(searchParams)
      }
    } else getInvoicesTable(searchParams)
  }, [getInvoicesTable, history, location.search, clientInfo, role])

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: invoicesTableStyles.header,
      rowStyle: (rowData) => ({
        backgroundColor: rowSelected?.factura === rowData.factura ? '#d6e0e6' : '#ffffff',
      }),
      draggable: false,
      pageSize: 10,
    }),
    [rowSelected] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const formattedColumns = useMemo(
    () =>
      InvoicesTableConstants.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.invoice.table.columns.${column.field}.title` }),
          cellStyle: {
            ...invoicesTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useCallback(invoiceDataPreparation(data, { intl }, formattedColumns), [
    data,
    formattedColumns,
    ,
  ])

  const handleRowClick = useCallback((_, row) => {
    if (rowSelected != row) setRowSelected(row)
    else setRowSelected(null)
  }, [])

  const onCloseDetail = () => {
    setRowSelected(null)
  }

  return (
    <>
      <MaterialTable
        data={processedData}
        options={tableOptions}
        columns={formattedColumns}
        onRowClick={handleRowClick}
        isLoading={loading}
        localization={useCallback(localization(formatMessage), [language])}
      />
      <InvoicesDetailComponent invoice={rowSelected} onCloseDetail={onCloseDetail} />
    </>
  )
}
