const { REACT_APP_WEB_API_URL, REACT_APP_REGISTRO_API_URL, REACT_APP_DOCUMENTUM_API_URL } = process.env

export const PUBLIC_WEB_INSTANCE_CONFIG = {
  baseURL: REACT_APP_WEB_API_URL,
  timeout: 30000,
}

export const PRIVATE_WEB_INSTANCE_CONFIG = {
  baseURL: REACT_APP_WEB_API_URL,
  timeout: 30000,
}

export const PUBLIC_REGISTRO_INSTANCE_CONFIG = {
  baseURL: REACT_APP_REGISTRO_API_URL,
  timeout: 30000,
}

export const PRIVATE_REGISTRO_INSTANCE_CONFIG = {
  baseURL: REACT_APP_REGISTRO_API_URL,
  timeout: 30000,
}

export const PRIVATE_DOCUMENTUM_INSTANCE_CONFIG = {
  baseURL: REACT_APP_DOCUMENTUM_API_URL,
  timeout: 30000,
}