export const USER_GRID_ROW_ITEMS = [
  {
    label: 'pages.profile.user.fields.name',
    value: 'name',
  },
  {
    label: 'pages.profile.user.fields.email',
    value: 'email',
  },
  {
    label: 'pages.profile.user.fields.fecha_alta',
    value: 'created_at',
    isDate: true,
  },
  {
    label: 'pages.profile.user.fields.role',
    value: 'role',
  },
  /*{
    label: 'pages.profile.user.fields.notifications',
    value: 'notifications',
  } */
]

export const SUPPLY_GRID_ROW_ITEMS = []
