import axios from 'axios'

import store from '../../redux/store'
import { setAuthData } from '../../../features/global'
import { loadLocalStorage, removeAllLocalStorage, saveLocalStorage } from '../../utils'

import {
  PRIVATE_DOCUMENTUM_INSTANCE_CONFIG,
  PRIVATE_REGISTRO_INSTANCE_CONFIG,
  PRIVATE_WEB_INSTANCE_CONFIG,
  PUBLIC_REGISTRO_INSTANCE_CONFIG,
  PUBLIC_WEB_INSTANCE_CONFIG,
} from '../constants'

// Create instances for WEB API
const publicWebInstance = axios.create(PUBLIC_WEB_INSTANCE_CONFIG)
const privateWebInstance = axios.create(PRIVATE_WEB_INSTANCE_CONFIG)

// Create instances for REGISTRO API
const publicRegistroInstance = axios.create(PUBLIC_REGISTRO_INSTANCE_CONFIG)
const privateRegistroInstance = axios.create(PRIVATE_REGISTRO_INSTANCE_CONFIG)

// Create instances for DOCUMENTUM API
const privateDocumentumInstance = axios.create(PRIVATE_DOCUMENTUM_INSTANCE_CONFIG)

const configurePrivateInstance = (instance) => {
  instance.interceptors.request.use((request) => {
    const userState = loadLocalStorage('userData')
    const obfuscation = loadLocalStorage('obfuscation')

    if (userState?.access_token) {
      request.headers.Authorization = 'Bearer ' + userState.access_token
    }
    if (obfuscation?.token) {
      request.headers['JWT-Token'] = obfuscation.token
    }
    return request
  })

  let refreshTokenPool = { requestCount: 0 }

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const { status, config } = error.response
      const userState = loadLocalStorage('userData')
      if (config.url === 'refresh' || status !== 401 || error.config.retry) {
        throw error
      }
      try {
        if (!refreshTokenPool.promise) {
          const payload = { refresh_token: userState.refresh_token }
          refreshTokenPool.promise = privateWebInstance
            .post('refresh', payload)
            .then((response) => {
              const payload = { ...userState, ...response.data }
              saveLocalStorage('userState', payload)
              store.dispatch(setAuthData({ ...payload, isAuthorized: true }))
              return response
            })
            .catch(() => {
              removeAllLocalStorage()

              throw new Error('token_refresh_failure')
            })
          refreshTokenPool.requestCount = 1
        } else {
          refreshTokenPool.requestCount++
        }
        const response = await refreshTokenPool.promise
        refreshTokenPool.requestCount--
        if (refreshTokenPool.requestCount === 0) {
          refreshTokenPool.promise = null
        }
        const newRequestConfig = {
          ...config,
          retry: true,
          headers: { ...config.headers, Authorization: 'Bearer ' + response.data.token },
        }
        return instance(newRequestConfig)
      } catch {
        console.log('Error fetching new access token')
        saveLocalStorage('glp', {})
        setTimeout(() => window.location.reload(), 1000)
        throw new Error('token_refresh_failure')
      }
    },
  )
}

configurePrivateInstance(privateWebInstance)
configurePrivateInstance(privateRegistroInstance)
configurePrivateInstance(privateDocumentumInstance)

export const instances = {
  publicWebInstance,
  privateWebInstance,
  publicRegistroInstance,
  privateRegistroInstance,
  privateDocumentumInstance,
}