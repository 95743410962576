import packageJson from '../../../../../package.json'

const { REACT_APP_ENVIRONMENT } = process.env

export let FOOTER_LINKS = [
  {
    id: 'layout.public.footer.upper.contact.label',
    link: 'https://www.gnredesglp.es/glp-inicio-2/glp-contacto/',
  },
  {
    id: 'layout.public.footer.upper.accesibility.label',
    link: 'https://www.gnredesglp.es/glp-inicio-2/accesibilidad/',
  },
  {
    id: 'layout.public.footer.upper.legalAdvice.label',
    link: 'https://www.gnredesglp.es/glp-inicio-2/nota-legal/',
  },
  {
    id: 'layout.public.footer.upper.cookiesAdvice.label',
    link: 'https://www.gnredesglp.es/glp-inicio-2/politica-de-cookies/',
  },
  {
    id: 'layout.public.footer.upper.privacyPolicy.label',
    link: 'https://www.gnredesglp.es/politica-de-privacidad/',
  },
]

export let CONTACT_PHONE = '900 510 511'

export let DEFAULT_ROUTE = '/login'

export const APP_VERSION_MSSG = `Version ${packageJson.version} (${REACT_APP_ENVIRONMENT})`
