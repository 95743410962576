export const pwdRecoveryStyles = (theme) => ({
  messageWrapper: {
    textTransform: 'none',
  },
  link: {
    marginLeft: 4,
    cursor: 'pointer',
  },
  message: {
    color: theme.palette.primary.main,
    fontFamily: 'FSEmeric-Medium',
    marginLeft: 4,
    textDecoration: 'underline',
  },
})
