import { createSlice } from '@reduxjs/toolkit'

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    authData: { isAuthorized: false, isTwoFactorAuthenticated: false },
    configData: {},
    isLoading: true,
    locale: 'es-ES',
  },
  reducers: {
    setAuthData: (state, { payload }) => {
      state.authData = payload
      state.isLoading = false
    },
    setConfigData: (state, { payload }) => {
      state.configData = payload
    },
    setGlobalLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setLocale: (state, { payload }) => {
      state.locale = payload
    },
    setTwoFactorAuthentication: (state, { payload }) => {
      console.log('setTwoFactorAuthentication', payload)
      state.authData.isTwoFactorAuthenticated = payload // Actualizar 2FA
    },
  },
})

export const globalReducer = globalSlice.reducer

export const {
  setAuthData,
  setConfigData,
  setGlobalLoading,
  setLocale,
  setTwoFactorAuthentication,
} = globalSlice.actions
