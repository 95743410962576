import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Box, Drawer, IconButton, ListItemIcon, Menu, MenuItem, MenuList, withStyles } from '@material-ui/core'
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'
import CloseIcon from '@material-ui/icons/Close'

import { menuStyles } from './menu.styles'

export const MenuComponent = withStyles(menuStyles)(
  ({ classes, items, isMobileWidth, mobileView }) => {
    const { formatMessage } = useIntl()
    const history = useHistory()

    const [drawerOpen, setDrawerOpen] = useState(false)

    const handleNavigate = (path) => {
      setDrawerOpen(!drawerOpen)
      history.push(path)
    }

    const toggleDrawer = (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
      setDrawerOpen(!drawerOpen)
    }

    const MenuItemCollapsable = ({ item }) => {
      const [anchorEl, setAnchorEl] = React.useState(null)

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
      }

      const handleClose = () => {
        setAnchorEl(null)
      }

      return (
        <>
          <MenuItem
            classeName={classes.menuItem}
            selected={item.children.some((item) => item.path === history.location.pathname)}
            onClick={handleClick}
          >
            {formatMessage({ id: item.label })}
          </MenuItem>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItems items={item.children} />
          </Menu>
        </>
      )
    }

    const MenuItems = ({ items }) =>
      items.map((item, i) => (
        <MenuItem
          key={i}
          selected={item.path === history.location.pathname}
          onClick={() => handleNavigate(item.path)}
          className={classes.menuItem}
        >
          {item.icon && (
            <ListItemIcon>
              <img src={item.icon} />
            </ListItemIcon>
          )}
          {formatMessage({ id: item.label })}
        </MenuItem>
      ))

    return isMobileWidth || mobileView ? (
      <>
        <IconButton onClick={toggleDrawer}>
          <MenuOutlinedIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          <Box p={2}>
            <Box mb={1}>
              <IconButton onClick={toggleDrawer}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Box>
            {items.map((item, i) => {
              return (
                <Box key={i}>
                  {item.children && item.children.length ? (
                    <Box key={i}>
                      <Box key={i} pb={1} fontSize={20}>
                        <MenuItem
                          classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                          selected={item.children.some(
                            (item) => item.path === history.location.pathname
                          )}
                        >
                          {formatMessage({ id: item.label })}
                        </MenuItem>
                      </Box>
                      <Box ml={4}>
                        <MenuItems items={item.children} />
                      </Box>
                    </Box>
                  ) : (
                    <MenuItem
                      key={i}
                      selected={item.path === history.location.pathname}
                      onClick={() => handleNavigate(item.path)}
                      classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                    >
                      {formatMessage({ id: item.label })}
                    </MenuItem>
                  )}
                </Box>
              )
            })}
          </Box>
        </Drawer>
      </>
    ) : (
      <MenuList className={classes.horizontal}>
        {items.map((item, i) => {
          return (
            <Box key={i}>
              {item.children && item.children.length ? (
                <MenuItemCollapsable item={item} />
              ) : (
                <MenuItem
                  key={i}
                  selected={item.path === history.location.pathname}
                  onClick={() => handleNavigate(item.path)}
                  classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                >
                  {item.icon && (
                    <ListItemIcon className={classes.menuItemIcon}>
                      <img src={item.icon} />
                    </ListItemIcon>
                  )}
                  {formatMessage({ id: item.label })}
                </MenuItem>
              )}
            </Box>
          )
        })}
      </MenuList>
    )
  }
)
