import React, { useEffect, useState } from 'react'
import { invoicesHeaderStyles } from './invoices-header.styles'
import { TextInput, ThemeButton } from '../../../../ui'
import { Box, Collapse, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'

import { useIntl } from 'react-intl'
import { FilterInput } from '../../../../ui/filter-input'
import { FilterDateRangeComponent } from '../../../../ui/filter-date-range'
import { useInvoicesContext } from '../../contexts'

const initialFiltersState = {
  _search: '',
  contracts: '',
  fecha_factura_start: null,
  fecha_factura_end: null,
  fecha_vencimiento_start: null,
  fecha_vencimiento_end: null,
}

const mockContracts = [
  {
    key: 'ES0237022200011153EGP001',
    value: 'ES0237022200011153EGP001 - BERROCAL, 0003 46 2 A - MADRID, MORALZARZAL (28411)',
  },
]

export const InvoicesHeaderComponent = () => {
  const intl = useIntl()
  const { formatMessage } = intl

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [formState, setFormState] = useState(initialFiltersState)
  const [contracts, setContracts] = useState([])

  const { originalData, filterTableData } = useInvoicesContext()

  const handleChangeForm = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  function convertToDate(dateString) {
    const [day, month, year] = dateString.split('/').map(Number)
    return new Date(year, month - 1, day) // Restar 1 al mes porque los meses en Date son 0-indexados
  }

  const applyFilters = () => {
    if (Object.keys(formState).length === 0) {
      filterTableData(originalData)
    } else {
      const filtered_data = originalData.filter((invoice) => {
        let isMatch = true

        for (const key in formState) {
          if (!formState[key]) continue

          switch (key) {
            case 'fecha_factura_start':
              if (convertToDate(invoice.fecha_factura) < formState[key]) {
                isMatch = false
              }
              break
            case 'fecha_factura_end':
              if (convertToDate(invoice.fecha_factura) > formState[key]) {
                isMatch = false
              }
              break
            case 'fecha_vencimiento_start':
              if (convertToDate(invoice.fecha_vencimiento) < formState[key]) {
                isMatch = false
              }
              break
            case 'fecha_vencimiento_end':
              if (convertToDate(invoice.fecha_vencimiento) > formState[key]) {
                isMatch = false
              }
              break
            case '_search':
              if (!invoice.factura.toLowerCase().includes(formState[key].toLowerCase())) {
                isMatch = false
              }
              break
            case 'contract':
              if (invoice.contrato !== formState[key]) {
                isMatch = false
              }
              break
          }

          if (!isMatch) break
        }

        return isMatch
      });
      filterTableData(filtered_data)
    }
  }

  const getContracts = () => {
    const tmp_contracts = []
    for (let i = 0; i < originalData.length; i++) {
      const contract = originalData[i].contrato
      if (!tmp_contracts.some(c => c.key === contract)) {
        tmp_contracts.push({
          key: originalData[i].contrato,
          value: originalData[i].contrato + ' - ' + originalData[i].direccion_suministro,
        })
      }
    }
    setContracts(tmp_contracts)
  }

  useEffect(() => {
    applyFilters()
  }, [formState])

  useEffect(() => {
    getContracts()
  }, [originalData])

  const handleSubmitForm = (event) => {
    event.preventDefault()
    applyFilters()
  }

  const filterCleanup = () => {
    setFormState(initialFiltersState)
    filterTableData(originalData)
  }

  const handleToggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }


  return (
    <header style={invoicesHeaderStyles.headerContainer}>
      <div style={invoicesHeaderStyles.searchContainer}>
        <TextInput
          spaceless="true"
          name="_search"
          placeholder={'Buscar factura'}
          value={formState['_search']}
          onChange={handleChangeForm}
          onBlur={handleSubmitForm}
          onKeyDown={(e) => e.keyCode === 13 && handleSubmitForm(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ThemeButton
          color="primary"
          onClick={filterCleanup}
          style={{ minWidth: '130px', margin: '10px 0' }}
        >
          {formatMessage({ id: `filters.clear.button` })}
        </ThemeButton>
        <ThemeButton
          variant="outlined"
          endIcon={<FilterListIcon />}
          onClick={handleToggleFilters}
          style={{ margin: '10px 0' }}
        >
          {formatMessage({ id: `filters.toggle.button` })}
        </ThemeButton>
      </div>
      <Collapse in={filtersOpen} style={invoicesHeaderStyles.collapseContainer}>
        <Box style={invoicesHeaderStyles.filtersContainer}>
          <FilterInput
            name={'contract'}
            values={contracts}
            value={formState['contract']}
            onChange={handleChangeForm}
            label={formatMessage({ id: `pages.invoice.table.header.contracts.title` })}
          />
          <FilterDateRangeComponent
            name={'fecha_factura'}
            label={formatMessage({ id: `pages.invoice.table.header.fecha_factura.title` })}
            onChange={handleChangeForm}
            startValue={formState['fecha_factura_start']}
            endValue={formState['fecha_factura_end']}
          />
          <FilterDateRangeComponent
            name={'fecha_vencimiento'}
            label={formatMessage({ id: `pages.invoice.table.header.fecha_vencimiento.title` })}
            onChange={handleChangeForm}
            startValue={formState['fecha_vencimiento_start']}
            endValue={formState['fecha_vencimiento_end']}
          />
        </Box>
      </Collapse>

    </header>
  )
}