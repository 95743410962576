export const LEGAL_CONDITIONS_URL = 'https://www.gnredesglp.es/glp-inicio-2/nota-legal/'

export const DATA_POLICY_URL = 'https://www.gnredesglp.es/politica-de-privacidad/'

export const IDENTIFICATOR_TYPES = [
  { key: 'NIF', value: 'NIF' },
  { key: 'NIE', value: 'NIE' },
  { key: 'PASAPORTE', value: 'PASAPORTE' },
]

export const CONTRACTS_TYPE = [
  { key: 'CUPS', value: 'CUPS' },
  { key: 'CONTRATO', value: 'CONTRATO' },
]