import React from 'react'
import { Box, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { ConfirmSignupFormComponent } from './confirm-signup-form.component'

export const ConfirmSignUpDialog = ({ username, password, close }) => {
  const { formatMessage } = useIntl()
  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.signup.confirmDialog.title' })}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h6" color="red">
            {formatMessage({ id: 'pages.signup.confirmDialog.text' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ConfirmSignupFormComponent username={username} password={password} close={close} />
      </DialogContent>
    </Box>
  )
}