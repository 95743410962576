import React, { useEffect, useState } from 'react'
import { contractsHeaderStyles } from './contracts-header.styles'
import { TextInput } from '../../../../ui'
import { InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { useContractsContext } from '../../contexts'

const initialFiltersState = {
  _search: '',
}

export const ContractsHeaderComponent = () => {
  const intl = useIntl()
  const location = useLocation()
  const { formatMessage } = intl

  const [formState, setFormState] = useState(initialFiltersState)
  const { originalData, filterTableData } = useContractsContext()

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      if (urlSearchParams['contrato']) {
        setFormState({ ...formState, ['_search']: urlSearchParams['contrato'] })
      }
    }
  }, [location.search])

  const handleChangeForm = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
    if (event.target.name !== '_search') applyFilters()
  }

  useEffect(() => {
    applyFilters()
  }, [formState])

  const applyFilters = () => {
    if (Object.keys(formState).length === 0) {
      filterTableData(originalData)
    } else {
      const filtered_data = originalData.filter((contract) => {
        let isMatch = true

        for (const key in formState) {
          if (!formState[key]) continue

          switch (key) {
            case '_search':
              const searchTerm = formState[key].toLowerCase();
              if (
                !contract.contrato.toLowerCase().includes(searchTerm) &&
                !contract.direccion_correspondencia.toLowerCase().includes(searchTerm)
              ) {
                isMatch = false
              }
              break
          }

          if (!isMatch) break
        }

        return isMatch
      })
      filterTableData(filtered_data)
    }
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    applyFilters()
  }


  return (
    <header style={contractsHeaderStyles.headerContainer}>
      <div style={contractsHeaderStyles.searchContainer}>
        <TextInput
          spaceless="true"
          name="_search"
          placeholder={'Buscar contrato'}
          value={formState['_search']}
          onChange={handleChangeForm}
          onBlur={handleSubmitForm}
          onKeyDown={(e) => e.keyCode === 13 && handleSubmitForm(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </header>
  )
}
