import React from 'react'
import { useIntl } from 'react-intl'
import { Tooltip, Typography, withStyles } from '@material-ui/core'

import { useWidth } from '../../../../core/utils'
import { publicLayoutStyles } from './public-layout.styles'
import { APP_VERSION_MSSG, CONTACT_PHONE, FOOTER_LINKS } from '../constants'
import LogoGNRedesGLPFull from '../../../../resources/images/logo_GNRedesGLP_full.svg'
import LogoGNRedesGLP from '../../../../resources/images/logo_GNRedesGLP.svg'

export const PublicLayoutComponent = withStyles(publicLayoutStyles)(({ children, classes }) => {
  let { formatMessage } = useIntl()
  let screenWidth = useWidth()
  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className={classes.headerLeftContent}>
          <Tooltip title={APP_VERSION_MSSG}>
            {screenWidth === 'xs' ? (
              <img src={LogoGNRedesGLP} alt={'nedgia logo'} style={{ width: 'auto', height: '60px' }} />
            ) : (
              <img
                src={LogoGNRedesGLPFull}
                alt="Nedgia_Logo"
                style={{ width: 'auto', height: '60px' }}
              />
            )}
          </Tooltip>
        </div>
        <div className={classes.headerRightContent}>
          <Typography variant="h6">
            {formatMessage({ id: 'layout.public.header.right.upper.label' })}
          </Typography>
          <Typography variant="body1">
            {formatMessage(
              { id: 'layout.public.header.right.bottom.label' },
              { phone: CONTACT_PHONE }
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.bodyWrapper}>{children}</div>
      <div className={classes.footerWrapper}>
        <div className={classes.footerContent}>
          <Typography variant="body1" paragraph>
            {FOOTER_LINKS.map((item, i) => (
              <span key={i}>
                <span className={classes.footerLink} onClick={() => window.open(item.link)}>
                  {formatMessage({ id: item.id })}
                </span>
                {i !== FOOTER_LINKS.length - 1 && '|'}
              </span>
            ))}
          </Typography>
          <Typography variant="body1">
            © {new Date().getFullYear()} Gas Natural - Redes GLP
          </Typography>
        </div>
      </div>
    </div>
  )
})
