import { useIntl } from 'react-intl'
import { requests } from '../../../core/ajax'
import { useCallback } from 'react'
import { feedback } from '../../../core/feedback'

export const useSignUpCalls = () => {
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))


  const verifyCode = useCallback((username, code) =>
    new Promise((resolve, reject) => {
      console.log('Username:', username, ' - Code:', code)
      requests.confirmRegister({ 'username': username, 'code': code })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log('Error:', error)
          const { response } = error
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              feedback('error', response.data.message)
              break
            default:
              defaultError()
          }
          return reject(response)
        })
    }), [])

  return {
    verifyCode,
  }
}
