import { Box, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ThemeButton } from '../../../../ui'
import { invoicesSearchStyles } from './invoices-header.styles'
import { useSearchCalls } from '../../hooks/search-calls.hook'
import { useDispatch } from 'react-redux'
import { setClientData } from '../../slices/searchUser.slice'
import { loadLocalStorage } from '../../../../core/utils'

export const InvoicesSearchComponent = () => {
  const intl = useIntl()
  const [userIdentifier, setUserIdentifier] = useState(null)
  const [error, setError] = useState(false)
  const { formatMessage } = intl
  const dispatch = useDispatch()
  const { searchClient } = useSearchCalls()

  const handleSearch = () => {
    if (userIdentifier) {
      searchClient(userIdentifier).then((response) => {
        dispatch(setClientData(response?.data))
        setError(false)
      }).catch((error) => {
        console.log(error)
        if (error?.status === 403) {
          setError(true)
        } else setError(true)
        return error?.status
      })
    }
  }

  const handleChange = (event) => {
    setUserIdentifier(event.target.value)
  }

  useEffect(() => {
    const client = loadLocalStorage('client')
    if (client) {
      dispatch(setClientData(client))
      setUserIdentifier(client.dni)
    }
  }, [dispatch])

  return (
    <Box>
      <Typography variant="h5" color="primary" paragraph align={'center'}>
        {formatMessage({ id: 'pages.invoice.search.title' })}
      </Typography>
      <Box style={invoicesSearchStyles.searchContainer}>
        <TextField
          label={formatMessage({ id: 'pages.invoice.search.input.label' })}
          variant="outlined"
          value={userIdentifier}
          onChange={handleChange}
        />
        <ThemeButton
          color="primary"
          onClick={handleSearch}
          style={{ minWidth: '130px', margin: '10px 0' }}
        >
          {formatMessage({ id: `pages.invoice.search.button.label` })}
        </ThemeButton>
        </Box>
          {error && (
            <div style={invoicesSearchStyles.dniErrorContainer}>
              <span style={invoicesSearchStyles.iconWarning}>⚠️</span>
              <p style={invoicesSearchStyles.dniError}>
                {formatMessage({ id: 'pages.incorrect.passport' })}
              </p>
            </div>
          )}
        </Box>
  )
}
