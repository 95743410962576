import { useCallback } from 'react'
import { requests } from '../../../core/ajax'

export const useSearchCalls = () => {
  const searchClient = useCallback(
    (client_id) =>
      new Promise((resolve, reject) => {
        requests.infoClient('?client=' + client_id)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [], // eslint-disable-line
  )

  return {
    searchClient,
  }
}