import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link, Typography, withStyles } from '@material-ui/core'

import { ModalContext } from '../../../../ui/modal/contexts'
import { PwdRecoveryDialogComponent } from './pwd-recovery-dialog.component'
import { pwdRecoveryStyles } from './pwd-recovery.styles'

export const PwdRecoveryMessageComponent = withStyles(pwdRecoveryStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const handleOpenModal = () =>
    open({
      Component: PwdRecoveryDialogComponent,
      data: {},
      type: 'responsive',
    })

  return (
    <div className={classes.messageWrapper}>
      <Typography variant="body2" display="inline" color="textSecondary">
        {formatMessage({ id: 'pages.login.form.pwdRecovery.message1' })}
      </Typography>
      <Link color="primary" className={classes.link} onClick={handleOpenModal}>
        <Typography variant="subtitle2" display="inline" className={classes.message}>
          {formatMessage({ id: 'pages.login.form.pwdRecovery.message2' })}
        </Typography>
      </Link>
    </div>
  )
})
