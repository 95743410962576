import { createSlice } from '@reduxjs/toolkit'
import { saveLocalStorage } from '../../../core/utils'


const searchUserSlice = createSlice({
  name: 'searchUser',
  initialState: {
    isLoading: false,
    user: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
  },
})

export const searchUserReducer = searchUserSlice.reducer

export const selectClient = (state) => state.searchUser.user

export const { setUser, setLoading } = searchUserSlice.actions

export const setClientData = (data) => (dispatch) => {
  dispatch(setLoading(true))
  dispatch(setUser(data))
  dispatch(setLoading(false))
  saveLocalStorage('client', data)
}