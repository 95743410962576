import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAuthData, setLocale } from '../slices'
import { removeAllLocalStorage, saveLocalStorage } from '../../../core/utils'
import { requests } from '../../../core/ajax'
import { setUserData } from '../../profile/slices/profile.slice'


export const useGlobalActions = () => {
  const dispatch = useDispatch()

  const loginUser = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        const payload = {
          username: data.username,
          password: data.password,
        }
        requests.login(payload)
          .then((response) => {
            saveLocalStorage('userData', response.data)
            getUserInfo().then((user_response) => {

              dispatch(setAuthData({ ...response.data, isAuthorized: true }))
              resolve(user_response)
            })
              .catch((reason) => reject(reason))
          })
          .catch((reason) => {
            removeAllLocalStorage()
            return reject(reason)
          })
      }),
    [], // eslint-disable-line
  )

  const getUserInfo = useCallback(() =>
      new Promise((resolve, reject) => {
        requests.infoClient()
          .then((response) => {
            dispatch(setUserData(response.data))
            resolve(response)
          })
          .catch(() => reject())
      })
    , [])

  const logoutUser = useCallback(() => {
    removeAllLocalStorage()
    dispatch(setAuthData({ isAuthorized: false }))
  }, []) // eslint-disable-line

  const changeLanguage = (language) => {
    dispatch(setLocale(language))
  }

  return {
    loginUser,
    logoutUser,
    changeLanguage,
  }
}
