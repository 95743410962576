export const loadLocalStorage = (key) => {
  try {
    const serializedState = localStorage.getItem('glp')
    return JSON.parse(serializedState)[key] ? JSON.parse(serializedState)[key] : undefined
  } catch (err) {
    return undefined
  }
}

export const saveLocalStorage = (key, state) => {
  try {
    const serializedState = localStorage.getItem('glp')
    let currentState = serializedState ? JSON.parse(serializedState) : {}

    currentState[key] = state

    localStorage.setItem('glp', JSON.stringify(currentState))
  } catch (err) {
    console.error('Error guardando en localStorage', err)
  }
}

export const removeAllLocalStorage = () => {
  try {
    localStorage.removeItem('glp')
  } catch (err) {
    console.error('Error eliminando localStorage', err)
  }
}
