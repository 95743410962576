import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Checkbox, Grid, InputAdornment, Link, Typography, withStyles } from '@material-ui/core'
import { feedback } from '../../../core/feedback'
import { PasswordInput, SelectInput, TextInput, ThemeButton } from '../../../ui'
import { signupFormStyles } from './signup-form.styles'
import { isValidCUPS, isValidEmail, isValidNIE, isValidNIF, verifyPwd } from '../../../core/utils'
import { CONTRACTS_TYPE, DATA_POLICY_URL, IDENTIFICATOR_TYPES } from '../constants'
import CupsinfoDialogComponent from './cupsinfo-dialog.component'
import { Alert } from '@material-ui/lab'
import { requests } from '../../../core/ajax'
import { ConfirmSignUpDialog } from './confirm-modal'
import { ModalContext } from '../../../ui/modal/contexts'

export const SignupFormComponent = withStyles(signupFormStyles)(() => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const initialFormState = {
    identifier_type: 'NIF',
    identifier: '',
    cups_type: 'CUPS',
    cups: '',
    telefono: '',
    email: '',
    email_confirmation: '',
    acepta_politica_privacidad: true,
    acepta_comunicaciones_comerciales: false,
  }

  const [formState, setFormState] = useState(initialFormState)
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleOpenConfirmDialog = () =>
    open({
      Component: ConfirmSignUpDialog,
      props: {
        close: () => {
        }, // Se maneja el cierre desde el diálogo directamente
      },
      data: {
        username: formState['identifier'],
        password: formState['password'],
      },
      type: 'responsive',
    })

  const completeSubmitForm = async () => {
    try {
      setLoading(true)
      const payload = {
        username: formState['identifier'],
        usr_password: formState['password'],
        tipo_identificacion: formState['identifier_type'],
        email: formState['email'],
        tipo_contrato: formState['cups_type'],
        contrato: formState['cups'],
      }
      const signupUser = await requests.register(payload)
      if (signupUser) {
        feedback(
          'success',
          'Petición de registro realizada. Recibirás un correo para formalizar el registro.',
        )
        setLoading(false)
        handleOpenConfirmDialog()
      }
    } catch {
      feedback(
        'error',
        'No ha sido posible realizar el registro. Por favor, contacte con el administrador.',
      )
      setLoading(false)
    }
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    if (step === 1) {
      if (formState['identifier_type'] === 'NIF' && !isValidNIF(formState['identifier'])) return setErrorMssg('Introduce NIF válido')
      if (formState['identifier_type'] === 'NIE' && !isValidNIE(formState['identifier'])) return setErrorMssg('Introduce NIE válido')
      if (formState['identifier_type'] === 'NIE' && !formState['identifier']) return setErrorMssg('Introduce el pasaporte')
      if (formState['cups_type'] === 'CUPS' && !isValidCUPS(formState['cups'])) return setErrorMssg('El CUPS introducido no es válido')
      if (formState['cups_type'] === 'CONTRATO' && !formState['cups']) return setErrorMssg('El Contrato introducido no es válido')

      setErrorMssg('')
      setStep(2)
    } else if (step === 2) {
      const pwdValidation = verifyPwd(formState['password'], formState['repeatPassword'])
      if (!isValidEmail(formState['email'])) return setErrorMssg('Introduce una dirección de email válida')
      if (formState['email'] !== formState['email_confirmation']) return setErrorMssg('El correo introducido y su confirmación no coinciden')
      if (!pwdValidation.isValid) return setErrorMssg(pwdValidation.errorMssg)
      setErrorMssg('')
      await completeSubmitForm()
    }
  }

  const handleCheckComunicacionesComerciales = () => {
    setFormState({
      ...formState,
      acepta_comunicaciones_comerciales: !formState.acepta_comunicaciones_comerciales,
    })
  }

  const handleOpenPrivacyModal = () => window.open(DATA_POLICY_URL)
  return (
    <>

      <Typography variant="h6" gutterBottom style={{ textAlign: 'center', marginBottom: '1em' }}>
        {step === 1 ? (formatMessage({ id: 'pages.signup.subtitle.step1' })) : (formatMessage({ id: 'pages.signup.subtitle.step2' }))}
      </Typography>
      <form onSubmit={handleSubmitForm}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <SelectInput
              required
              value={formState['identifier_type']}
              values={IDENTIFICATOR_TYPES}
              onChange={handleChangeForm}
              disabled={step !== 1}
              name="identifier_type"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextInput
              value={formState['identifier']}
              name="identifier"
              placeholder={formatMessage({ id: 'pages.signup.form.identifier.placeholder' })}
              onChange={handleChangeForm}
              required
              disabled={step !== 1}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInput
              required
              value={formState['cups_type']}
              values={CONTRACTS_TYPE}
              onChange={handleChangeForm}
              disabled={step !== 1}
              name="cups_type"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextInput
              value={formState['cups']}
              name="cups"
              placeholder={formatMessage({ id: 'pages.signup.form.cups.placeholder' })}
              onChange={handleChangeForm}
              required
              disabled={step !== 1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CupsinfoDialogComponent />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {step === 1 ? (<Grid item md={12}>
            <Box>
              <Box mt={1}>
                <Typography color="primary" style={{ fontWeight: 'bold' }}>
                  {formatMessage({ id: 'pages.signup.terms.title' })}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="caption" color="primary">
                  {formatMessage({ id: 'pages.signup.terms.text.1' })}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="caption" color="primary">
                  {formatMessage({ id: 'pages.signup.privacy.policy.header' })}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'flex-start'}>
                <Checkbox color="primary" size="small" onClick={handleCheckComunicacionesComerciales} />
                <Box mt={1}>
                  <Typography variant="caption" color="primary">
                    {formatMessage({ id: 'pages.signup.comercial.comunications.text.1' })}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1}>
                <Typography variant="caption" display="inline" color="primary">
                  {formatMessage({ id: 'pages.signup.privacy.policy.text.1' })}
                </Typography>
                <Link onClick={handleOpenPrivacyModal}>
                  <Typography
                    display="inline"
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    color="textSecondary"
                    variant="caption"
                  >
                    {formatMessage({ id: 'pages.signup.form.checkbox.message4' })}
                  </Typography>
                </Link>
                <Typography display="inline" variant="caption" color="primary">
                  {formatMessage({ id: 'pages.signup.privacy.policy.text.2' })}
                </Typography>
              </Box>
            </Box>
          </Grid>) : (<><Grid item xs={12} sm={6}>
            <TextInput
              type="email"
              required
              value={formState['email']}
              name="email"
              title={formatMessage({ id: 'pages.signup.form.email.title' })}
              placeholder={formatMessage({ id: 'pages.signup.form.email.placeholder' })}
              onChange={handleChangeForm}
            />
          </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                type="email"
                required
                value={formState['email_confirmation']}
                name="email_confirmation"
                title={formatMessage({ id: 'pages.signup.form.repitEmail.title' })}
                placeholder={formatMessage({ id: 'pages.signup.form.email.placeholder' })}
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <PasswordInput
                    value={formState['password']}
                    name="password"
                    title={formatMessage({ id: 'pages.signup.form.password.title' })}
                    onChange={handleChangeForm}
                    required
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PasswordInput
                    value={formState['repeatPassword']}
                    name="repeatPassword"
                    title={formatMessage({ id: 'pages.signup.form.repitPassword.title' })}
                    placeholder={formatMessage({ id: 'pages.signup.form.repitPassword.placeholder' })}
                    onChange={handleChangeForm}
                    required
                  />
                </Grid>
                <Box bgcolor="grey">
                  <Typography variant="caption" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
                    {formatMessage({ id: 'pages.pwdRecovery.extra' })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>)}
        </Grid>
        {errorMssg && (
          <Alert severity="error" style={{ marginTop: '2em' }}>{errorMssg}</Alert>
        )}
        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          <ThemeButton type="submit" fullWidth loading={loading} style={{ margin: '15px auto', maxWidth: '350px' }}>
            {step === 1 ? (formatMessage({ id: 'pages.signup.form.step1.button.label' })) : (formatMessage({ id: 'pages.signup.form.step2.button.label' }))}
          </ThemeButton>
          {step === 2 && (<ThemeButton
            variant="outlined"
            fullWidth
            loading={loading}
            disabled={loading}
            onClick={() => setStep(1)}
            style={{ margin: '5px auto', maxWidth: '350px' }}
          >
            {formatMessage({ id: 'pages.signup.form.step2.atrasButton.label' })}
          </ThemeButton>)}
        </div>
      </form>
    </>
  )
})
