import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Container, Typography, withStyles } from '@material-ui/core'

import { LoginFormComponent } from '../../../features/login'
import { loginStyles } from './login.styles'
import LogoGNRedesGLPFull from '../../../resources/images/logo_GNRedesGLP_full.svg'


export const LoginPage = withStyles(loginStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.root}>
      <div className={classes.leftWrapper}>
        <Container className={classes.container} maxWidth="sm">
          <div className={classes.panel}>
            <img
              src={LogoGNRedesGLPFull}
              alt="Nedgia_Logo"
              style={{ width: '250px', height: 'auto', margin: '0 auto', display: 'block', marginBottom: '1.5em' }}
            />
            <Typography variant="h5" color="primary" gutterBottom style={{ textAlign: 'center' }}>
              {formatMessage({ id: 'pages.login.title' })}
            </Typography>
            <LoginFormComponent />
            <div className={classes.signupWrapper}>
              <Typography variant="body2" display="inline" color="textSecondary">
                {formatMessage({ id: 'pages.login.signup.message1' })}
              </Typography>
              <Link to={{ pathname: '/signup' }} className={classes.signupLink}>
                <Typography variant="subtitle2" display="inline" className={classes.signupMessage}>
                  {formatMessage({ id: 'pages.login.signup.message2' })}
                </Typography>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
})
