import React from 'react'
import { Box, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { UnblurInfoFormComponent } from './two-factor-form.component'

export const UnblurInformationDialog = ({ close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'unblur.information.dialog' })}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h6" color="red" align="center">
            {formatMessage({ id: 'unblur.information.dialog.information' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <UnblurInfoFormComponent close={close} />
      </DialogContent>
    </Box>
  )
}

export default UnblurInformationDialog
