import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography, withStyles } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { ReactComponent as EditIcon } from '../../../../resources/images/icon_edit.svg'
import { ModalContext } from '../../../../ui/modal/contexts'
import { ConfirmationDialog, GridRow, ThemeButton } from '../../../../ui'
import { ChangePwdDialog } from '../change-pwd'
import { USER_GRID_ROW_ITEMS } from '../../constants'
import { useGlobalActions } from '../../../global'
import { profileUserStyles } from './profile-user.styles'
import { selectRole, selectUser } from '../../slices'
import { useProfileCalls } from '../../hooks/profile-calls.hook'

export const ProfileUserComponent = withStyles(profileUserStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { logoutUser } = useGlobalActions()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const user = useSelector(selectUser)
  const { deregister } = useProfileCalls()
  const role = useSelector(selectRole)

  const handleLogout = () => {
    logoutUser()
  }

  const handleDeRegister = () => {
    const callback = async () => {
      deregister().then(() => {
        handleLogout()
      })
    }

    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.profile.user.deregister-confirm.title' }),
        text: formatMessage({ id: 'pages.profile.user.deregister-confirm.text' }),
        yesText: formatMessage({ id: 'global.true' }),
        noText: formatMessage({ id: 'global.false' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }


  const handleOpenChangePwdModal = () =>
    open({
      Component: ChangePwdDialog,
      type: 'responsive',
    })

  return (
    <Box mb={3}>
      <Box display="flex" mb={1} flexWrap="wrap">
        <Box display="flex" alignItems="center" flexGrow={4} mr={2}>
          {user?.picture ? <img src={user.picture} alt="user" className={classes.userImg} /> :
            <AccountCircleOutlinedIcon className={classes.userImg} />}
          <Typography variant="h4" color="primary">
            {`${user?.name || ''}`}
          </Typography>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <ThemeButton
              color="primary"
              icon={<EditIcon className={classes.primaryButtonIcon} />}
              onClick={handleOpenChangePwdModal}
            >
              {/* {formatMessage({ id: 'pages.profile.header.button1' })} */}
              Cambiar contraseña
            </ThemeButton>
          </Box>
          <ThemeButton variant="outlined" onClick={handleLogout}>
            {formatMessage({ id: 'pages.profile.header.button2' })}
          </ThemeButton>
        </Box>
      </Box>
      <Box bgcolor="common.white" borderRadius={6} p={3}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.profile.user.title' })}:
        </Typography>
        <GridRow items={USER_GRID_ROW_ITEMS} data={user} justify="space-between" />
      </Box>
      {role !== 'ATR' && (
        <Box display="flex" justifyContent={'end'}>
          <ThemeButton variant="outlined" color="error" onClick={handleDeRegister}>
            {formatMessage({ id: 'pages.profile.header.deregister' })}
          </ThemeButton>
        </Box>
      )}
    </Box>
  )
})
