export const InvoicesReducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_TABLE_DATA':
      return {
        ...state,
        data: payload.data,
        originalData: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case 'FILTER_TABLE_DATA':
      return {
        ...state,
        data: payload,
        loading: false,
        msLoaded: Date.now(),
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'SET_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      }
    default:
      throw new Error('unknown action')
  }
}