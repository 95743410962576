export const InvoicesTableConstants = [
  { field: 'factura', sorting: true, align: 'left', width: 120 },
  { field: 'fecha_factura', sorting: true, align: 'right', width: 120 },
  { field: 'fecha_vencimiento', sorting: true, align: 'right', width: 120 },
  {
    field: 'importe',
    sorting: true,
    align: 'right',
    width: 120,
    render: rowData => new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(rowData.importe),
    cellStyle: { fontWeight: 'bold', color: '#004165' },
  },
]