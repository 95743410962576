import React from 'react'
import { Box, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { ChangePwdFormComponent } from './change-pwd-form.component'
import { useIntl } from 'react-intl'

export const ChangePwdDialogComponent = ({ close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">

            {formatMessage({ id: 'pages.profile.header.button3' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ChangePwdFormComponent {...{ close }} />
      </DialogContent>
    </Box>
  )
}
