import { Hidden, colors } from "@material-ui/core";

export const invoicesSearchStyles = {
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '1em',
  },
  dniErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center horizontally
    color: 'red'
  },
  iconWarning: {
    marginRight: '8px'
  },
  dniError: {
    margin: 0 // Remove any default margin from the <p> tag
  }
}
