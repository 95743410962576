import { useCallback } from 'react'
import { feedback } from '../../../core/feedback'
import { requests } from '../../../core/ajax'
import { useIntl } from 'react-intl'
import { saveLocalStorage } from '../../../core/utils'
import { setTwoFactorAuthentication } from '../../global'
import { useDispatch } from 'react-redux'

export const useContractsCalls = (callback, deps) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getContracts = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        requests
          .contracts(config)
          .then((response) => {
            if (response) {
              if (response.status === 206) {
                saveLocalStorage('obfuscation', '')
                dispatch(setTwoFactorAuthentication(false))
              }
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    []
  )

  return {
    getContracts,
  }
}
