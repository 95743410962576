import React, { useState } from 'react'
import {
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { passwordInputStyles } from './password-input.styles'

export const PasswordInput = withStyles(passwordInputStyles)(({ classes, ...props }) => {
  const [visibility, setVisibility] = useState(false)

  return (
    <FormControl className={classes.root}>
      <div className={classes.title}>
        {props.title}
        {props.tooltip && (
          <span style={{ fontSize: 16, marginLeft: 4 }}>
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {props.tooltip}
                </Typography>
              }
            >
              <Icon fontSize="inherit">help_outline</Icon>
            </Tooltip>
          </span>
        )}
      </div>
      <TextField
        {...props}
        type={visibility ? 'text' : 'password'}
        variant="outlined"
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={props.onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton style={{ padding: '2px' }} onClick={() => setVisibility(!visibility)}>
                {visibility ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText id="my-helper-text" error={props.error}>
        {props.error ? props.errorMessage : props.helpMessage}
      </FormHelperText>
    </FormControl>
  )
})
