export const contractsDataPreparation = (data, { intl }, columns = []) => {
    return data.map((item) => {
      const formattedData = {}
      columns.forEach((column) => {
        if (column.field === 'importe' && item[column.field]) {
          formattedData[column.field] = parseFloat(item[column.field])
        }
      })
      return {
        ...item,
        ...formattedData,
      }
    })
  }