export const isValidCUPS = (CUPS) => {
  let status
  let RegExPattern = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/
  if ((CUPS.match(RegExPattern)) && (CUPS !== '')) {
    let CUPS_16 = CUPS.substr(2, 16)
    let control = CUPS.substr(18, 2)
    let letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']

    let fmodv = CUPS_16 % 529
    let imod = parseInt(fmodv)
    let quotient = Math.floor(imod / 23)
    let remainder = imod % 23

    let dc1 = letters[quotient]
    let dc2 = letters[remainder]
    status = (control === dc1 + dc2)
  } else {
    status = false
  }
  return status
}