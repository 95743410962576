import React, { createContext, useContext } from 'react'
import { useContractsReducer } from '../hooks/contracts-reducer.hook'


const ContractsContext = createContext()

export const ContractsContextProvider = ({ children }) => {
  const reducer = useContractsReducer()
  return <ContractsContext.Provider value={reducer}>{children}</ContractsContext.Provider>
}

export const useContractsContext = () => useContext(ContractsContext)