import { createSlice } from '@reduxjs/toolkit'
import { saveLocalStorage } from '../../../core/utils'

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    user: {},
    role: '',
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setUser: (state, { payload }) => {
      state.user = {
        created_at: payload?.register_date,
        role: payload?.role,
        email: payload?.email,
        name: payload?.full_name,
        apellidos: payload?.apellidos,
        nickname: payload?.full_name,
        picture: payload?.picture,
      }
    },
    setRole: (state, { payload }) => {
      state.role = payload?.role
    },
  },
})

export const profileReducer = profileSlice.reducer

export const selectUser = (state) => state.profile.user

export const selectRole = (state) => state.profile.role

export const { setUser, setRole, setLoading } = profileSlice.actions

export const setUserData = (data) => (dispatch) => {
  dispatch(setLoading(true))
  dispatch(setUser(data))
  dispatch(setRole(data))
  dispatch(setLoading(false))

  saveLocalStorage('user', data)
}