import React from 'react'
import { languages } from './language-picker.constants'
import { useGlobalActions } from '../../features/global'
import { useSelector } from 'react-redux'
import { FormControl, MenuItem, Select } from '@material-ui/core'


export function LanguagePickerComponent() {
  const { changeLanguage } = useGlobalActions()
  const language = useSelector((state) => state.global.locale)


  const onChangeLanguage = event => changeLanguage(event.target.value)
  return (
    <FormControl style={{ height: '40px', minWidth: 120 }} variant="outlined" size="small">
      <Select
        onChange={onChangeLanguage} value={language}
      >
        {languages.map(({ name, code }) => (
          <MenuItem key={code} value={code}>{name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}