import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { contractsStyles } from './contracts.styles'
import { InvoicesSearch } from '../../../features/invoices'
import { ContractsHeader, ContractsTable } from '../../../features/contracts'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../features/profile/slices/profile.slice'
import { SearchInfoComponent } from '../../../features/searchUser/components/search-info'
import { ContractsContext } from '../../../features/contracts/contexts'

export const ContractsPage = withStyles(contractsStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const role = useSelector(selectRole)

  return (
    <ContractsContext>
    <div className={classes.root}>
      <Container className={classes.container} maxWidth={'lg'}>
        {role === 'ATR' && (<Box mb={2}>
          <InvoicesSearch />
          <SearchInfoComponent />
        </Box>)}
        <div className={classes.titleWrapper}>
          <Typography variant="h4" color="primary" paragraph>
            {formatMessage({ id: 'pages.dashboard.title' })}
          </Typography>
        </div>
        <Box mb={2}>
          <ContractsHeader />
        </Box>
        <Box mb={2}>
          <ContractsTable />
        </Box>
      </Container>
    </div>
    </ContractsContext>
  )
})
