import es_ES from './es_ES.json'
import ca_ES from './ca_ES.json'
import en_EN from './en_EN.json'
import eu_ES from './eu_ES.json'
import ga_ES from './ga_ES.json'

const LOCALE_DEFAULT = 'es-ES'

const availableMessages = {
  'es-ES': es_ES,
  'ca-ES': ca_ES,
  'en-EN': en_EN,
  'eu-ES': eu_ES,
  'ga-ES': ga_ES,
}

const locale = availableMessages[navigator.language] ? navigator.language : LOCALE_DEFAULT

const messages = availableMessages[locale]

export { messages, locale, availableMessages as LOCALES }
