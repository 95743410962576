import { useIntl } from 'react-intl'
import { feedback } from '../../signup/mocks'
import { requests } from '../../../core/ajax'
import { useCallback } from 'react'

export const useProfileCalls = (callback, deps) => {
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const changePassword = useCallback((data) =>
    new Promise((resolve, reject) => {
      requests.update(data)
          .then((response) => {
            if (response) {
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      },
    ), [])

  const deregister = useCallback(() =>
    new Promise((resolve, reject) => {
        requests.deregister()
          .then((response) => {
            if (response) {
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      },
    ), [])

  return {
    changePassword,
    deregister,
  }
}