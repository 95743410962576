import { ContractsReducer } from '../reducers'
import { useCallback, useReducer } from 'react'
import { CONTRACTS_INITIAL_STATE } from '../reducers/contracts-reducer.constants'
import { useContractsCalls } from './contracts-calls.hook'
import { useSelector } from 'react-redux'

export const useContractsReducer = () => {
  const [state, dispatch] = useReducer(ContractsReducer, CONTRACTS_INITIAL_STATE)
  const { isTwoFactorAuthenticated } = useSelector((state) => state.global.authData)

  const { getContracts } = useContractsCalls()

  const getContractsTable = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: 'SET_LOADING',
      })
      getContracts(config)
        .then(({ data }) => {
          dispatch({
            type: 'SET_TABLE_DATA',
            payload: {
              data: data,
              search: search,
            },
          })
        })
        .catch(() => {
          dispatch({
            type: 'SET_FAILURE',
          })
        })
    },
    [isTwoFactorAuthenticated]
  )

  const filterTableData = useCallback(
    (data) => {
      dispatch({
        type: 'SET_LOADING',
      })
      dispatch({
        type: 'FILTER_TABLE_DATA',
        payload: data,
      })
    },

    []
  )

  return {
    ...state,
    dispatch,
    getContractsTable,
    filterTableData,
  }
}
