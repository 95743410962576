import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { InfoOutlined } from '@material-ui/icons'
import factura_CUPS from '../../../resources/images/factura_CUPS.png'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: 'block',
  },
}))(MuiDialogActions)

export default function CupsinfoDialogComponent() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IconButton aria-label="Info" color="primary" onClick={handleClickOpen} style={{ padding: 0 }}>
        <InfoOutlined />
      </IconButton>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
        <DialogContent>
          <img src={factura_CUPS} style={{ width: '100%', height: 'auto' }} alt="Ejemplo factura" />
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '0.5rem' }}>
              <InfoOutlined color="primary" />
              <Typography variant="body2">
                Busca el CUPS en "detalle de la lectura, el consumo y la facturación" en tu factura.
              </Typography>
            </div>
            <Button autoFocus onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </div>

        </DialogActions>
      </Dialog>
    </div>
  )
}