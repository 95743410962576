import React from 'react'
import { Container, withStyles } from '@material-ui/core'

import { ProfileUser } from '../../../features/profile'
import { profileStyles } from './profile.styles'

export const ProfilePage = withStyles(profileStyles)(({ classes }) => {

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <ProfileUser />
      </Container>
    </div>
  )
})
