import React from 'react'
import { IntlProvider } from 'react-intl'

import { LOCALES } from './constants'
import { useSelector } from 'react-redux'


export const I18NProvider = ({ children }) => {

  const language = useSelector((state) => state.global.locale)
  return (
    <IntlProvider locale={language} messages={LOCALES[language]} defaultLocale={'es_ES'}>
    {children}
  </IntlProvider>
  )
}
