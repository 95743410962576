import InvoicesIcon from '../../../../resources/images/invoices_icon.svg'
import ContractsIcon from '../../../../resources/images/contracts_icon.svg'


export let MENU_ITEMS = [
  {
    label: 'layout.private.menu.invoices',
    path: '/invoices',
    icon: InvoicesIcon,
  },
  {
    label: 'layout.private.menu.contracts',
    path: '/contracts',
    icon: ContractsIcon,
  },
]

export let DEFAULT_ROUTE = '/invoices'

export let PROFILE_ROUTE = '/account'

export let SETTINGS_ROUTE = '/settings'
