import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'


const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
  },
  dateRangePicker: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  dateInput: {
    flex: 1,
    textAlign: 'center',
    paddingLeft: '5px',
    border: '1px solid #ccc',
    background: '#ffffffd9',
    '&:not(:last-child)': {
      borderRight: 'none',
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none !important',
    },
  },
}))

export const FilterDateRangeComponent = ({
                                           name, onChange = () => {
  }, label, startValue, endValue,
                                         }) => {
  const classes = useStyles()

  const handleStartDateChange = (date) => {
    if (endValue && date && date > endValue) {
      handleEndDateChange(null)
    }
    onChange({ target: { name: `${name}_start`, value: date } })
  }

  const handleEndDateChange = (date) => {
    onChange({ target: { name: `${name}_end`, value: date } })
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Box className={classes.inputContainer}>
        {label && <Typography variant="caption">{label}</Typography>}
        <Box className={classes.dateRangePicker}>
          <DatePicker
            className={classes.dateInput}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            emptyLabel="Desde"
            value={startValue}
            onChange={handleStartDateChange}
            maxDate={endValue}

          />
          <DatePicker
            className={classes.dateInput}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            emptyLabel="Hasta"
            value={endValue}
            onChange={handleEndDateChange}
            minDate={startValue}
          />
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  )
}