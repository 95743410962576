export const invoicesHeaderStyles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '1em',
    width: '100%',
  },
  collapseContainer: {
    width: '100%',
  },
  filtersContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    gap: '1em',
    width: '100%',
    flexWrap: 'wrap',
  },
}
