import { useIntl } from 'react-intl'
import { requests } from '../../../core/ajax'
import { useCallback } from 'react'
import { feedback } from '../../../core/feedback'
import { saveLocalStorage } from '../../../core/utils'
import { setTwoFactorAuthentication } from '../../global'
import { useDispatch } from 'react-redux'

export const useTwoFactorCalls = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }));
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }));

  const sendCode = useCallback(() =>
    new Promise((resolve, reject) => {
      requests.obfuscation()
        .then((response) => {
          if (response) {
            resolve(response)
          } else {
            noResponseError()
            return reject('No response')
          }
        })
        .catch((error) => {
          const { response } = error
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              feedback('error', response.data.message)
              break
            default:
              defaultError()
          }
          return reject(response.status)
        })
    }), [])


  const verifyCode = useCallback((code) =>
    new Promise((resolve, reject) => {
      requests.obfuscationRemoval('?code=' + code)
        .then((response) => {
          console.log('Response:', response.data)
          if (response.data) {
            saveLocalStorage('obfuscation', response.data)
            dispatch(setTwoFactorAuthentication(true))
            resolve(response.data)
          } else {
            noResponseError()
            return reject('No response')
          }
        })
        .catch((error) => {
          console.log('Error:', error)
          dispatch(setTwoFactorAuthentication(false))
          const { response } = error
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 400:
              feedback('error', response.data.message)
              break
            default:
              defaultError()
          }
          return reject(response.status)
        })
    }), []);

  return {
    sendCode,
    verifyCode,
  };
};
