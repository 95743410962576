import { createSlice } from '@reduxjs/toolkit'


const twoFactorSlice = createSlice({
  name: 'twoFactor',
  initialState: {
    twoFactor: null,

  },
  reducers: {
    setTwoFactor: (state, { payload }) => {
      state.twoFactor = payload
    },
  },
})

export const twoFactorReducer = twoFactorSlice.reducer

export const selectTwoFactor = (state) => state.twoFactor.twoFactor

export const { setTwoFactor } = twoFactorSlice.actions

export const setTwoFactorData = (data) => (dispatch) => {
  dispatch(setTwoFactor(data))
}

